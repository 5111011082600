.parking-map {
	$bezier: cubic-bezier(.17, .67, .46, .87);
	$duration: .2s;

	position: relative;
	display: flex;
	overflow: hidden;
	box-shadow: rgba(0 0 0 / 90%) 0 10px 20px -10px;
	opacity: 0;
	transition: opacity $duration $bezier;

	&--loaded {
		opacity: 1;
	}

	&__logout-button-container {
		position: absolute;
		z-index: 10;
		top: 10px;
		right: 10px;
	}

	&__image {
		display: block;
		width: 100%;
	}

	&__spots {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		user-select: none;
	}

	&__spot {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0 255 0 / 60%);
		border-radius: 2px;
		outline: 4px solid rgba(254 251 131 / 0%);
		transition: background-color $duration $bezier, outline-color $duration $bezier;

		&--is-occupied {
			background: rgba(255 0 0 / 60%);
		}
	}

	@media (hover: hover) {
		&__spot:hover {
			z-index: 300;
			cursor: pointer;
			outline: 4px solid rgba(254 251 131 / 100%);
		}
	}

	&__spot-name {
		z-index: 10;
		color: rgb(255 255 255);
		filter: drop-shadow(0 2px 2px rgb(0 0 0));
		font-size: 18px;
		font-weight: 600;
		pointer-events: none;
		transform: rotate3d(0, 0, 1, -35deg);
		user-select: none;

		@media screen and (max-width: 480px) {
			font-size: 12px;
		}
	}
}
