.login-form {
	position: relative;
	width: 308px;
	max-width: 100%;

	&__form {
		display: flex;
		flex-direction: column;
		margin-top: 48px;

		label {
			position: relative;
			margin-bottom: 30px;

			span {
				position: absolute;
				top: -16px;
				font-size: 13px;
			}

			&:last-of-type {
				margin-bottom: 20px;
			}
		}

		&__feedback {
			margin-bottom: 20px;
			color: rgb(255 0 0);
			font-weight: 700;
		}
	}

	[type='text'],
	[type='password'] {
		width: 100%;
		padding-bottom: 5px;
		border: none;
		border-bottom: 2px solid rgb(230 186 0);
		margin-top: 3px;
		margin-bottom: 10px;
		background: transparent;
		border-radius: 0;
		font-size: 16px;
		outline: none;
	}

	[type='text']:focus,
	[type='password']:focus {
		border: none;
		border-bottom: 2px solid rgb(0 193 201);
		background: transparent;
	}

	[type='submit']:disabled {
		background: rgb(225 225 225);
		box-shadow: rgba(0 0 0 / 20%) 0 11px 6px -10px;
		color: rgb(191 191 191);
		pointer-events: none;
	}
}
