.button {
	$transition: .2s cubic-bezier(0, .14, .15, 1);

	padding: 12px 16px;
	border: 0;
	margin-bottom: 24px;
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-appearance: none;
	background-color: rgb(230 186 0);
	box-shadow: rgba(0 0 0 / 40%) 0 10px 20px -10px;
	color: rgb(17 24 39);
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	outline: none;
	text-align: center;
	text-decoration: none;
	touch-action: manipulation;
	transition: background-color $transition, color $transition, box-shadow $transition;
	user-select: none;

	&:hover,
	&:focus {
		background-color: rgb(0 193 201);
		color: rgb(255 255 255);
	}
}
