* {
	padding: 0;
	margin: 0;
	font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

// Reset
/* stylelint-disable */
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	background-color: rgba(0, 0, 0, 0.05);
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
	content: none;
}

strong {
	font-weight: 700;
}

i {
	font-style: italic;
}

a {
	text-decoration: none;
	color: inherit;
}

button {
	font-family: inherit;
	-webkit-appearance: none;
	padding: 0;
	background: 0 0;
	border: 0;
	cursor: pointer;
	color: inherit;
}

button:disabled {
	cursor: initial;
}

input {
	border-radius: unset; //ios style reset
	background-clip: padding-box; //ios style reset

	&:focus {
		outline: none;
	}
}

.app {
	max-width: 975px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	padding: 0 24px;

	&__header {
		margin: 24px 0;
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;

		@media screen and (max-width: 480px) {
			flex-direction: column;
		}
	}

	// Weird fix for repainting after rotating device

	/* portrait */
	@media screen and (orientation: portrait) {
		position: relative;
	}

	/* landscape */
	@media screen and (orientation: landscape) {
		position: relative;
	}
}
