.yu-logo {
	position: relative;
	display: flex;
	width: 48px;
	height: 48px;
	align-items: center;
	margin: 0;
	line-height: 1;

	&__svg {
		position: absolute;
	}
}
