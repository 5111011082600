@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-style: italic;
	font-weight: 900;
	src:
		local('Proxima Nova Bold Italic'),
		local('Proxima-Nova-Bold-Italic'),
		url('./fonts/Proxima-Nova-Bold-Italic.otf') format('opentype');
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-style: bold;
	font-weight: 900;
	src:
		local('Proxima Nova Condensed Bold'),
		local('Proxima-Nova-Condensed-Bold'),
		url('./fonts/Proxima-Nova-Condensed-Bold.otf') format('opentype');
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-style: bold;
	font-weight: 800;
	src:
		local('Proxima Nova Bold'),
		local('Proxima-Nova-Bold'),
		url('./fonts/Proxima-Nova-Bold.otf') format('opentype');
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-style: bold;
	font-weight: 600;
	src:
		local('Proxima Nova Semibold'),
		local('Proxima-Nova-Semibold'),
		url('./fonts/Proxima-Nova-Semibold.otf') format('opentype');
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	src:
		local('Proxima Nova Regular'),
		local('Proxima-Nova-Regular'),
		url('./fonts/Proxima-Nova-Regular.otf') format('opentype');
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-style: italic;
	font-weight: 400;
	src:
		local('Proxima Nova Regular Italic'),
		local('Proxima-Nova-Regular-Italic'),
		url('./fonts/Proxima-Nova-Regular-Italic.otf') format('opentype');
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-style: italic;
	font-weight: 200;
	src:
		local('Proxima Nova Light Italic'),
		local('Proxima-Nova-Light-Italic'),
		url('./fonts/Proxima-Nova-Light-Italic.otf') format('opentype');
}

@font-face {
	font-display: swap;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 200;
	src:
		local('Proxima Nova Light'),
		local('Proxima-Nova-Light'),
		url('./fonts/Proxima-Nova-Light.otf') format('opentype');
}
